import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PasswordPolicyStrength } from '../../../models/account/password-policy.model';
import { SSOErrorResponseModel, SSOHttpErrorResponse } from '../../../models/errors.model';

export const newPasswordApiActions = createActionGroup({
  source: 'New Password Api',
  events: {
    'Password Change Successfully': emptyProps(),
    'Password Change Failed': props<{ error: SSOHttpErrorResponse['error'] }>(),
    'Password Policy Loaded Successfully': props<{ policy: PasswordPolicyStrength }>(),
    'Password Policy Loaded Failed': props<{ error: SSOErrorResponseModel }>(),

    'Reset password token verified successfully': emptyProps(),
    'Reset password token verification failed': props<{ userEmail: string | null }>(),

    'Reset Password Successfully': emptyProps(),
    'Reset Password Failed': props<{ error: SSOHttpErrorResponse['error'] }>(),
  },
});
