import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { DatePipe, UpperCasePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_DIALOG_CONFIG, DialogModule } from '@angular/cdk/dialog';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter, RouterOutlet } from '@angular/router';

import { provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore, Store } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { ChrComponentsModule } from '@ciphr-design-system/angular';

import { apiBaseUrlInterceptorFactory, HTTP_API_CLIENT } from '@ciphr/utils/data-adapters';
import { provideErrorMessageTranslationPath } from '@ciphr/shared/forms';
import { provideStaticTranslation } from '@ciphr/shared/translation';
import { SideContentsEffects } from '@ciphr/core/effects';
import { provideAppBranding } from '@ciphr/core/app-branding';

import { accountConfigFactory } from './core/factories/account-config.factory';
import { AccountEffects } from './state/account/account.effects';
import { accountFeature } from './state/account/account.reducer';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { customConfigInitializerFactory } from './core/factories/custom-config-initializer-factory';
import { CustomErrorHandler } from './core/error-handler';
import { RecaptchaEffects } from './state/recaptcha.effects';
import { SsoApiInterceptor } from './core/interceptors/sso-api-.interceptor';
import { SsoAuthPanelService } from './core/sso-auth-panel.service';
import { translationConfig } from './translations/translation.config';
import { UiEffects } from './state/ui.effects';
import { AUTH_PANEL_SERVICE } from '@ciphr/core/app-navigation/features';
import { SINGLE_SIGN_ON_HTTP_API_CLIENT } from '@ciphr/core/auth';
import { TenantInterceptor } from './core/interceptors/tenant.interceptor';
import { CookiesBannerComponent } from '@ciphr/core/cookies-banner';
import { UpperSnakeCasePipe } from '@ciphr/ui';
import { provideUserSession, UserSessionService } from '@ciphr/core/user-session';
import { ssoSessionFactory } from './core/factories/sso-session.factory';

import { registerLocalization } from '@ciphr-design-system/foundations';
import { provideFileExport } from '@ciphr/shared/file-export';

registerLocalization({
  path: '/assets/ds-translations',
  locale: 'en',
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    DialogModule,
    RouterOutlet,
    ChrComponentsModule.forRoot({
      components: true,
      defaultTheme: 'ciphr-light',
      iconsUrl: '/assets/icons',
      illustrationsUrl: 'assets/illustrations',
      polyfills: true,
    }),
    CookiesBannerComponent,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([apiBaseUrlInterceptorFactory({ name: 'sso', version: 1 })])),
    provideRouter(appRoutes),
    provideEffects(AccountEffects, RecaptchaEffects, UiEffects, SideContentsEffects),
    provideState(accountFeature),
    provideStore(),
    provideStoreDevtools({ logOnly: !isDevMode() }),
    provideErrorMessageTranslationPath('VALIDATIONS'),
    provideStaticTranslation(translationConfig),
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: { ...tooltipVariation, arrow: true, maxWidth: 'unset', placement: 'auto', theme: 'light' },
      },
    }),
    provideFileExport(HTTP_API_CLIENT, 3000),
    provideAppBranding(HTTP_API_CLIENT, 'sso', true),
    provideUserSession(HTTP_API_CLIENT, { useFactory: ssoSessionFactory, deps: [Store, UserSessionService] }),
    { provide: APP_INITIALIZER, useFactory: accountConfigFactory, deps: [Store], multi: true },
    { provide: APP_INITIALIZER, useFactory: customConfigInitializerFactory, multi: true },
    { provide: AUTH_PANEL_SERVICE, useClass: SsoAuthPanelService },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        ariaModal: true,
        panelClass: 'sso-dialog',
        hasBackdrop: true,
        role: 'dialog',
        autoFocus: 'first-tabbable',
        restoreFocus: true,
        closeOnNavigation: true,
        closeOnDestroy: true,
        closeOnOverlayDetachments: true,
      },
    },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: HTTP_API_CLIENT, useExisting: HttpClient },
    { provide: SINGLE_SIGN_ON_HTTP_API_CLIENT, useExisting: HttpClient },
    { provide: HTTP_INTERCEPTORS, useClass: SsoApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    DatePipe,
    UpperCasePipe,
    UpperSnakeCasePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
