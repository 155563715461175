export const enTranslation = {
  'CIPHR_BACKGROUND_WRAPPER.PHOTO_DESCRIPTION':
    'Picture with company brand identifying the HR system. It may contain pictures, graphics or illustrations related to your company brand. Alternatively it is a default CIPHR illustration of a woman with a mug flying across clouds.',
  'CIPHR_BACKGROUND_WRAPPER.LOGO_DESCRIPTION': 'Company log',
  'CIPHR_BACKGROUND_WRAPPER.POWERED_BY': 'Powered By',
  'CIPHR_BACKGROUND_WRAPPER.POWERED_BY_LOGO_DESCRIPTION': 'Ciphr company logo',

  'NAVIGATION.ADMIN': 'Ciphr Sign In Admin',
  'NAVIGATION.COMPANY_LOGO': 'Company logo',
  'NAVIGATION.DESKTOP': 'Desktop version',
  'NAVIGATION.LOG_OUT': 'Log out',
  'NAVIGATION.PROFILE': 'Personal Profile',
  'NAVIGATION.SEARCH': 'Search',

  'NAVIGATION.ADMIN.USERS': 'Users',
  'NAVIGATION.ADMIN.SETTINGS': 'Settings',
  'NAVIGATION.ADMIN.SINGLE_SIGN_ON': 'Single sign-on',
  'NAVIGATION.ADMIN.BRANDING': 'Branding',

  'LOGIN.BASIC_LOGIN.PAGE_TITLE': 'Sign in',
  'LOGIN.BASIC_LOGIN.LABELS.USERNAME': 'Email',
  'LOGIN.BASIC_LOGIN.LABELS.PASSWORD': 'Password',
  'LOGIN.BASIC_LOGIN.LABELS.IS_PERSISTENT': 'Remember my email on this device',
  'LOGIN.BASIC_LOGIN.LABELS.RETURNURL': 'Return url',

  'LOGIN.BASIC_LOGIN.ACTIONS.LOGIN': 'Login',
  'LOGIN.BASIC_LOGIN.ACTIONS.RESET_PASSWORD': 'Reset password',

  'LOGIN.SINGLE_SIGN_ON_LOGIN.PAGE_TITLE': 'Sign in',
  'LOGIN.SINGLE_SIGN_ON_LOGIN.PAGE_DESCRIPTION': 'Provide your email so we can  identify your login method',
  'LOGIN.SINGLE_SIGN_ON_LOGIN.LABELS.USERNAME': 'Email',
  'LOGIN.SINGLE_SIGN_ON_LOGIN.LABELS.RETURNURL': 'Return url',

  'LOGIN.SINGLE_SIGN_ON_LOGIN.ACTIONS.NEXT': 'Next',

  'NEW_PASSWORD.PAGE_TITLE': 'Set a new password',
  'NEW_PASSWORD.PAGE_DESCRIPTION': 'Enter your new password here.',
  'NEW_PASSWORD.LABELS.PASSWORD': 'Password',
  'NEW_PASSWORD.LABELS.REPEAT_PASSWORD': 'Repeat password',
  'NEW_PASSWORD.LABELS.TOKEN': 'Token',
  'NEW_PASSWORD.LABELS.USERID': 'User ID',
  'NEW_PASSWORD.MESSAGES.PASSWORD_CHANGED': 'Password has been updated successfully',

  'NEW_PASSWORD.INFORMATION_MESSAGE.TITLE': 'Your password must consist of:',
  'NEW_PASSWORD.INFORMATION_MESSAGE.WEAK': 'At least 8 characters',
  'NEW_PASSWORD.INFORMATION_MESSAGE.MEDIUM': 'At least 12 characters',
  'NEW_PASSWORD.INFORMATION_MESSAGE.STRONG': 'At least 16 characters',
  'NEW_PASSWORD.INFORMATION_MESSAGE.UPPERCASE': 'At least one uppercase letter',
  'NEW_PASSWORD.INFORMATION_MESSAGE.LOWERCASE': 'At least one lowercase letter',
  'NEW_PASSWORD.INFORMATION_MESSAGE.SPECIAL_CHARACTER': 'At least one special character (i.e. @, #, !, %)',
  'NEW_PASSWORD.INFORMATION_MESSAGE.DIGIT': 'At least one digit',

  'NEW_PASSWORD.ACTIONS.SET_PASSWORD': 'Set password',

  'RESET_PASSWORD.PAGE_TITLE': 'Reset password',
  'RESET_PASSWORD.PAGE_DESCRIPTION': 'Please provide your email to receive password reset instructions.',
  'RESET_PASSWORD.LABELS.EMAIL': 'Email',

  'RESET_PASSWORD.ACTIONS.SEND': 'Send',

  'RESET_PASSWORD.CONFIRMATION.TITLE': 'Email sent',
  'RESET_PASSWORD.CONFIRMATION.DESCRIPTION': 'Please, check your email box.',
  'RESET_PASSWORD.CONFIRMATION.ICON_TITLE': 'An icon with email box',

  'CHANGE_PASSWORD.DIALOG.TITLE': 'Change your password',
  'CHANGE_PASSWORD.MESSAGES.PASSWORD_EXPIRED.TITLE': 'Your password has expired',
  'CHANGE_PASSWORD.MESSAGES.PASSWORD_EXPIRED.DESCRIPTION': 'Please enter a new one.',
  'CHANGE_PASSWORD.MESSAGES.PASSWORD_POLICY': 'Your password does not match to password policy',
  'CHANGE_PASSWORD.MESSAGES.PASSWORD_POLICY.DESCRIPTION': 'Please enter a new one.',
  'CHANGE_PASSWORD.LABELS.CURRENT_PASSWORD': 'Current password',
  'CHANGE_PASSWORD.LABELS.NEW_PASSWORD': 'New password',
  'CHANGE_PASSWORD.LABELS.REPEAT_PASSWORD': 'Repeat new password',

  'CHANGE_PASSWORD.LABELS.CURRENTPASSWORD': '{{ CHANGE_PASSWORD.LABELS.CURRENT_PASSWORD }}',
  'CHANGE_PASSWORD.LABELS.NEWPASSWORD': '{{ CHANGE_PASSWORD.LABELS.NEW_PASSWORD }}',
  'CHANGE_PASSWORD.LABELS.REPEATPASSWORD': '{{ CHANGE_PASSWORD.LABELS.REPEAT_PASSWORD }}',

  'CHANGE_PASSWORD.PROFILE.SECTION.TITLE': 'Password',
  'CHANGE_PASSWORD.PROFILE.SECTION.LAST_CHANGED': 'Last changed',
  'CHANGE_PASSWORD.PROFILE.SECTION.PASSWORD_EXPIRES_ON': 'Password expires on',
  'CHANGE_PASSWORD.PROFILE.SECTION.ACTIONS.CHANGE_PASSWORD': 'Change password',

  'CHANGE_PASSWORD.ACTIONS.CANCEL': 'Cancel',
  'CHANGE_PASSWORD.ACTIONS.CHANGE': 'Change',

  'MULTI_FACTOR_AUTHENTICATION': 'Two-factor authentication',
  'MULTI_FACTOR_AUTHENTICATION_LOWERCASE': 'two-factor authentication',

  'LOGIN.MULTI_FACTOR_AUTHENTICATION.PAGE_TITLE': '{{ MULTI_FACTOR_AUTHENTICATION }}',
  'LOGIN.MULTI_FACTOR_AUTHENTICATION.ALTERNATIVE_METHODS': 'Sign in with',

  'MULTI_FACTOR_AUTHENTICATION.PROFILE.DEFAULT_LABEL': 'Default',
  'MULTI_FACTOR_AUTHENTICATION.PROFILE.METHODS.DESCRIPTION.APPLICATION':
    'Scan QR code in the authentication application to confirm your identity',
  'MULTI_FACTOR_AUTHENTICATION.PROFILE.METHODS.DESCRIPTION.EMAIL': 'You’ll receive a code to your email',
  'MULTI_FACTOR_AUTHENTICATION.PROFILE.METHODS.DESCRIPTION.SMS': 'You’ll receive a code to your phone number',

  'MULTI_FACTOR_AUTHENTICATION.PROFILE.ARIA.CONFIGURE_METHODS': 'Configure two factor for {{ method }}',

  'MULTI_FACTOR_AUTHENTICATION.METHODS.DESCRIPTION.APPLICATION': 'Please enter your two-factor code from authentication application',
  'MULTI_FACTOR_AUTHENTICATION.METHODS.DESCRIPTION.EMAIL': 'Please enter your two-factor code from  email',
  'MULTI_FACTOR_AUTHENTICATION.METHODS.DESCRIPTION.SMS': 'Please enter your two-factor code from text message',
  'MULTI_FACTOR_AUTHENTICATION.METHODS.DESCRIPTION.RECOVERYCODE': 'Please enter your recovery code',

  'MULTI_FACTOR_AUTHENTICATION.LABELS.CODE': 'Code',
  'MULTI_FACTOR_AUTHENTICATION.LABELS.EMAIL': 'Email',
  'MULTI_FACTOR_AUTHENTICATION.LABELS.PHONE': 'Phone number',
  'MULTI_FACTOR_AUTHENTICATION.LABELS.REMEMBER_BROWSER': 'Remember this browser',
  'MULTI_FACTOR_AUTHENTICATION.LABELS.APPLICATION': 'Authentication application',
  'MULTI_FACTOR_AUTHENTICATION.LABELS.SMS': 'Text message',
  'MULTI_FACTOR_AUTHENTICATION.LABELS.RECOVERYCODE': 'Recovery code',

  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.TITLE': 'Confirm authentication method',
  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.DESCRIPTION.EMAIL': 'Please enter your email to receive authentication code',
  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.DESCRIPTION.APPLICATION': 'Use authentication application to get authentication code',
  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.DESCRIPTION.PHONE': 'Please enter your phone number to receive authentication code',

  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.VALIDATION.EMAIL.REQUIRED': 'Email is required',
  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.VALIDATION.EMAIL.EMAIL': 'Invalid email format',
  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.VALIDATION.PHONE.REQUIRED': 'Phone number is required',
  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.VALIDATION.PHONE.MOBILEPHONEPATTERN':
    'Please provide phone number in valid format with country prefix eg. +55123123123',

  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.QR_CODE.TITLE': 'Scan this QR code with your authentication application',
  'MULTI_FACTOR_AUTHENTICATION.CONFIGURATION.QR_CODE.DESCRIPTION': 'Or enter following key',

  'MULTI_FACTOR_AUTHENTICATION.VERIFICATION.TITLE.EMAIL': 'Enter the code from email',
  'MULTI_FACTOR_AUTHENTICATION.VERIFICATION.TITLE.APPLICATION': 'Enter the code from authentication application',
  'MULTI_FACTOR_AUTHENTICATION.VERIFICATION.TITLE.PHONE': 'Enter the code from text-message',

  'MULTI_FACTOR_AUTHENTICATION.VERIFICATION.DESCRIPTION.EMAIL': 'Please enter below the code you received',
  'MULTI_FACTOR_AUTHENTICATION.VERIFICATION.DESCRIPTION.APPLICATION': 'Please enter below the code  from your authentication application',
  'MULTI_FACTOR_AUTHENTICATION.VERIFICATION.DESCRIPTION.SMS': 'Please enter below the code you received',

  'MULTI_FACTOR_AUTHENTICATION.DISABLE.TITLE': 'Disable {{ MULTI_FACTOR_AUTHENTICATION_LOWERCASE }}',

  'MULTI_FACTOR_AUTHENTICATION.METHODS.TITLE': 'Configure {{ MULTI_FACTOR_AUTHENTICATION_LOWERCASE }}',
  'MULTI_FACTOR_AUTHENTICATION.METHODS.SUB_TITLE': '{{ MULTI_FACTOR_AUTHENTICATION }} required',
  'MULTI_FACTOR_AUTHENTICATION.METHODS.DESCRIPTION': 'To sign in, you need to set up {{ MULTI_FACTOR_AUTHENTICATION_LOWERCASE }}.',
  'MULTI_FACTOR_AUTHENTICATION.METHODS.NEXT_STEP': 'Click Next to proceed.',

  'MULTI_FACTOR_AUTHENTICATION.ICON.EMAIL': 'An letter icon which present an email option to choose',
  'MULTI_FACTOR_AUTHENTICATION.ICON.SMS': 'Sms icon which present a letter with tree dot inside to choose sms option',
  'MULTI_FACTOR_AUTHENTICATION.ICON.APPLICATION': 'An icon which present qr code for application authentication option',
  'MULTI_FACTOR_AUTHENTICATION.ICON.RECOVERY_CODE': 'An icon which present stars inside a letter',

  'MULTI_FACTOR_AUTHENTICATION.MESSAGES.SEND_AGAIN_EMAIL':
    'An email with code was send, check your inbox and use the form above to confirm your identity',
  'MULTI_FACTOR_AUTHENTICATION.MESSAGES.SEND_AGAIN_TEXT_MESSAGE':
    'Text message with code was send, please check your mobile phone to confirm your identity',
  'MULTI_FACTOR_AUTHENTICATION.MESSAGES.MULTI_FACTOR_AUTHENTICATION_CONFIGURED':
    '{{ MULTI_FACTOR_AUTHENTICATION }} has been configured successfully',

  'MULTI_FACTOR_AUTHENTICATION.MESSAGES.MULTI_FACTOR_AUTHENTICATION_ADDED':
    '{{ method }} {{ MULTI_FACTOR_AUTHENTICATION_LOWERCASE }} method has been added successfully',
  'MULTI_FACTOR_AUTHENTICATION.MESSAGES.MULTI_FACTOR_AUTHENTICATION_DISABLED':
    '{{ MULTI_FACTOR_AUTHENTICATION_LOWERCASE }} {{ method }} method has been disabled successfully',
  'MULTI_FACTOR_AUTHENTICATION.MESSAGES.MULTI_FACTOR_AUTHENTICATION_CHANGED': 'Preferred method has been changed successfully',
  'MULTI_FACTOR_AUTHENTICATION.MESSAGES.MULTI_FACTOR_AUTHENTICATION_CHANGED_FAILED': 'Something went wrong with preferred method change',
  'MULTI_FACTOR_AUTHENTICATION.MESSAGES.PASSWORD_CHANGED': 'Password has been changed successfully',

  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.BACK': 'Back',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.CANCEL': 'Cancel',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.CONFIRM': 'Confirm',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.SEND_AGAIN': 'Send again',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.NEXT': 'Next',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.LOGIN': 'Login',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.VERIFY': 'Verify',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.ADD_2FA': 'Add 2FA method',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.DISABLE': 'Disable',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.RECONFIGURE': 'Reconfigure',
  'MULTI_FACTOR_AUTHENTICATION.ACTIONS.SET_DEFAULT_METHOD': 'Set as a default method',

  'MULTI_FACTOR_AUTHENTICATION.SEND_CODE.DESCRIPTION': "Didn't receive the code?",

  'RECOVERY_CODES.PROFILE.SECTION.TITLE': 'Recovery codes',
  'RECOVERY_CODES.PROFILE.DIALOG.TITLE': 'Generate {{ MULTI_FACTOR_AUTHENTICATION_LOWERCASE }} codes',
  'RECOVERY_CODES.PROFILE.ACTIONS.GENERATE': 'Generate recovery codes',
  'RECOVERY_CODES.PROFILE.ACTIONS.DONE': 'Done',

  'ADMIN.PAGE_TITLE': 'Admin page',
  'ADMIN.ACTIONS.NAVIGATE_TO_PROFILE': 'Personal profile',
  'ADMIN.ARIA.MENU_BUTTON': 'Main menu',

  'PROFILE.PAGE.TITLE': 'User profile',
  'PROFILE.PAGE.ACTIONS.NAVIGATE_TO_ADMIN': 'Admin',

  'USER_CONFIGURATION.SECTION_TITLE': 'Main information',
  'USER_CONFIGURATION.LABELS.FORENAMES': 'First name',
  'USER_CONFIGURATION.LABELS.LASTNAME': 'Last name',
  'USER_CONFIGURATION.LABELS.TITLE': 'Title',
  'USER_CONFIGURATION.LABELS.KNOWN_AS': 'Known as',
  'USER_CONFIGURATION.LABELS.WORK_EMAIL': 'Email',
  'USER_CONFIGURATION.LABELS.MOBILE_NUMBER': 'Mobile phone',
  'USER_CONFIGURATION.LABELS.ENABLED_FORM': 'Enabled from',
  'USER_CONFIGURATION.LABELS.ENABLED_TO': 'Enabled to',

  'USER_CONFIGURATION.MESSAGES.EDIT': 'You can edit this data in your Personal Details area in Ciphr product.',
  'USER_CONFIGURATION.MESSAGES.DEFAULT_GROUP': 'New user will be added by default to the SSO General group.',
  'USER_CONFIGURATION.MESSAGES.ENABLED_FROM':
    'Setting an enabled from date will result in granting user access on that date. Leaving this field empty will result in restricting access.',
  'USER_CONFIGURATION.MESSAGES.ENABLED_TO': 'Date provided in enabled to should be later than enabled from date.',
  'USER_CONFIGURATION.MESSAGES.FILE_SIZE': 'Max file size exceeded ({{ maxFileSize }}kb)',

  'USER_CONFIGURATION.ACTIONS.EDIT': 'Edit information',

  'USERS.LIST.PAGE_TITLE': 'Users',
  'USERS.EDIT.PAGE_TITLE': 'Edit user',
  'USERS.EDIT.PAGE_SUBTITLE': 'Editing for',
  'USERS.CREATION.PAGE_TITLE': 'Create user',
  'USERS.DETAIL.PAGE_TITLE': 'User details',

  'USERS.BLOCK.DIALOG.TITLE': 'Block user',
  'USERS.BLOCK.DIALOG.BULK_TITLE': 'Block users',
  'USERS.BLOCK.DIALOG.SUBTITLE': 'Are you sure you want to block this user? ',
  'USERS.BLOCK.DIALOG.LABELS.DATE': 'Without end date',
  'USERS.BLOCK.DIALOG.LABELS.LOCKOUT_END_ON': '{{ USERS.LABELS.LOCKED_TO }}',
  'USERS.BLOCK.DIALOG.LABELS.PLACEHOLDER_DATE': 'Select date',
  'USERS.BLOCK.DIALOG.INFORMATION_MESSAGE': 'You won’t be able to block all users from this list',

  'USERS.UNBLOCK.DIALOG.TITLE': 'Are you sure you want to active {{ displayName }}?',
  'USERS.UNBLOCK.DIALOG.BULK_TITLE': 'Unblock users',
  'USERS.UNBLOCK.DIALOG.INFORMATION_MESSAGE': 'You won’t be able to unblock all users from this list',

  'USERS.RESET_PASSWORD.DIALOG.TITLE': 'Are you sure you want to reset the password for {{ displayName }}?',
  'USERS.RESET_PASSWORD.DIALOG.BULK_TITLE': 'Reset passwords',
  'USERS.RESET_PASSWORD.DIALOG.INFORMATION_MESSAGE': 'You won’t be able to reset password all users from this list',

  'USERS.RESET_2FA.DIALOG.TITLE': 'Are you sure you want to reset {{ MULTI_FACTOR_AUTHENTICATION_LOWERCASE }} for {{ displayName }}',

  'USERS.CHANGE_ROLE.DIALOG.TITLE': 'Change role',
  'USERS.CHANGE_ROLE.LABELS.GROUPS': 'Assign groups to the group admin',
  'USERS.CHANGE_ROLE.LABELS.GROUP_DESCRIPTION': 'The group admin will be able to access accounts assigned to the selected groups',

  'USERS.DELETE_USER.DIALOG.TITLE': 'Are you sure you want to delete user {{ displayName }}?',
  'USERS.DELETE_USERS.DIALOG.TITLE': 'Delete users',
  'USERS.DELETE_USER.DIALOG.INFORMATION_MESSAGE': 'You won’t be able to delete all users from this list',

  'USERS.ENABLED_DATES.DIALOG.TITLE': 'Enable from/to',
  'USERS.ENABLED_DATES.DIALOG.LABELS.ENABLED_FROM': 'Enable from',
  'USERS.ENABLED_DATES.DIALOG.LABELS.ENABLED_TO': 'Enable to',
  'USERS.ENABLED_DATES.DIALOG.LABELS.CLEAR_ENABLED_FROM': "Clear 'Enabled from' field",
  'USERS.ENABLED_DATES.DIALOG.LABELS.CLEAR_ENABLED_TO': "Clear 'Enabled to' field",
  'USERS.ENABLED_DATES.DIALOG.LABELS.PLACEHOLDER_DATE': 'Select date',

  'USERS.LABELS.FIRSTNAME': 'First name',
  'USERS.LABELS.LASTNAME': 'Surname',
  'USERS.LABELS.USERNAME': 'Username',
  'USERS.LABELS.ROLE': 'Role',
  'USERS.LABELS.STATUS': 'Status',
  'USERS.LABELS.GROUP': 'Group',
  'USERS.LABELS.ENABLED_FROM': 'Enabled from',
  'USERS.LABELS.ENABLED_TO': 'Enabled to',
  'USERS.LABELS.LOCKED_TO': 'Blocked until',
  'USERS.LABELS.INDEFINITELY': 'Indefinitely',
  'USERS.LABELS.ACTIONS': 'Actions',
  'USERS.LABELS.PASSWORD_CHANGED': 'Password changed',

  'USERS.STATUS.ACTIVE': 'Active',
  'USERS.STATUS.BLOCKED': 'Blocked',
  'USERS.STATUS.CAN_BE_DELETED': 'Can be deleted',
  'USERS.STATUS.CANNOT_BE_DELETED': 'Not able to delete',
  'USERS.STATUS.CAN_RESET_PASSWORD': 'Can reset password',
  'USERS.STATUS.CANNOT_RESET_PASSWORD': 'Not able to reset password',
  'USERS.STATUS.CAN_BE_BLOCKED': 'Can be blocked',
  'USERS.STATUS.CANNOT_BE_BLOCKED': 'Not able to block',
  'USERS.STATUS.CAN_BE_UNBLOCKED': 'Can be unblocked',
  'USERS.STATUS.CANNOT_BE_UNBLOCKED': 'Not able to unblock',

  'USERS.ROLES.BACKOFFICEADMIN': 'Back office admin',
  'USERS.ROLES.GLOBALADMIN': 'Global admin',
  'USERS.ROLES.GROUPADMIN': 'Group admin',
  'USERS.ROLES.EMPLOYEE': 'Employee',

  'USERS.ACTIONS.ADD': 'Add new user',
  'USERS.ACTIONS.CREATE': 'Create user',
  'USERS.ACTIONS.SAVE': 'Save changes',
  'USERS.ACTIONS.CANCEL': 'Cancel',
  'USERS.ACTIONS.SEARCH': 'Search user',
  'USERS.ACTIONS.VIEW_DETAILS': 'View details',
  'USERS.ACTIONS.CHANGE_ROLE': 'Change role',
  'USERS.ACTIONS.RESET_PASSWORD': 'Reset password',
  'USERS.ACTIONS.RESET_2FA': 'Reset 2FA',
  'USERS.ACTIONS.DISABLE_2FA': '2FA disabled',
  'USERS.ACTIONS.BLOCK': 'Block user',
  'USERS.ACTIONS.UNBLOCK': 'Unblock user',
  'USERS.ACTIONS.DELETE_USER': 'Delete user',
  'USERS.ACTIONS.BULK': 'Actions',
  'USERS.ACTIONS.BULK.DELETE': 'Delete users',
  'USERS.ACTIONS.BULK.RESET_PASSWORDS': 'Reset passwords',
  'USERS.ACTIONS.BULK.BLOCK_USERS': 'Block users',
  'USERS.ACTIONS.BULK.UNBLOCK_USERS': 'Unblock users',
  'USERS.ACTIONS.BULK.ENABLED_DATES': 'Enable',
  'USERS.ACTIONS.BULK.ENABLED_DATES_NAV': 'Enable from/to',

  'USERS.ACTIONS.ARIA.MENU': 'User actions button',

  'USERS.MESSAGES.BLOCK_SUCCESSFULLY': 'User has been blocked successfully',
  'USERS.MESSAGES.BULK_BLOCK_SUCCESSFULLY': 'Users have been blocked successfully',
  'USERS.MESSAGES.UNBLOCK_SUCCESSFULLY': 'User has been unblocked successfully',
  'USERS.MESSAGES.RESET_PASSWORD_SUCCESSFULLY': 'Password has been reset successfully',
  'USERS.MESSAGES.BULK_RESET_PASSWORD_SUCCESSFULLY': 'Passwords has been reset successfully',
  'USERS.MESSAGES.BULK_UNBLOCK_SUCCESSFULLY': 'Users have been unblocked',
  'USERS.MESSAGES.REST_2FA_SUCCESSFULLY': '{{ MULTI_FACTOR_AUTHENTICATION}} has been reset successfully',
  'USERS.MESSAGES.ENABLED_DATES_SUCCESSFULLY': 'Date have been changed successfully',
  'USERS.MESSAGES.ROLE_UPDATED_SUCCESSFULLY': 'Role has been updated successfully',
  'USERS.MESSAGES.USER_DELETED_SUCCESSFULLY': 'User has been deleted successfully',
  'USERS.MESSAGES.USERS_DELETED_SUCCESSFULLY': 'Users have been deleted successfully',
  'USERS.MESSAGES.USER_CREATED': 'User has been created successfully',
  'USERS.MESSAGES.USER_UPDATED': 'User has been updated successfully',

  'LOGIN_HISTORY.TITLE': 'Login history',
  'LOGIN_HISTORY.ACTIONS.SEARCH': 'Search by IP address',
  'LOGIN_HISTORY.LIST.LABELS.STATUS': 'Status',
  'LOGIN_HISTORY.LIST.LABELS.LOGIN_DATE': 'Login date',
  'LOGIN_HISTORY.LIST.LABELS.LOGOUT_DATE': 'Logout date',
  'LOGIN_HISTORY.LIST.LABELS.IP_ADDRESS': 'IP address',
  'LOGIN_HISTORY.LIST.LABELS.BROWSER': 'Browser',

  'SETTING.PASSWORD_POLICY.SECTION_TITLE': 'Password policy',
  'SETTING.PASSWORD_POLICY.PASSWORD_STRENGTH_SECTION_TITLE': 'Password policy strength',
  'SETTING.PASSWORD_POLICY.PASSWORD_EXPIRATION_TIME_SECTION_TITLE': 'Password will expire after',
  'SETTING.PASSWORD_POLICY.PASSWORD_STRENGTH.WEAK': 'Weak',
  'SETTING.PASSWORD_POLICY.PASSWORD_STRENGTH.MEDIUM': 'Medium',
  'SETTING.PASSWORD_POLICY.PASSWORD_STRENGTH.STRONG': 'Strong',

  'SETTING.PASSWORD_POLICY.PASSWORD_STRENGTH.RULES.WEAK':
    'At least 8 characters, at least one uppercase letter, at least one special character, at least one lowercase letter, at least one digit.',
  'SETTING.PASSWORD_POLICY.PASSWORD_STRENGTH.RULES.MEDIUM':
    'At least 12 characters, at least one uppercase letter, at least one special character, at least one lowercase letter, at least one digit.',
  'SETTING.PASSWORD_POLICY.PASSWORD_STRENGTH.RULES.STRONG':
    'At least 16 characters, at least one uppercase letter, at least one special character, at least one lowercase letter, at least one digit.',

  'SETTING.PASSWORD_POLICY.DIALOG.TITLE': '{{ SETTING.PASSWORD_POLICY.SECTION_TITLE }}',
  'SETTING.PASSWORD_POLICY.DIALOG.SUBTITLE': 'Password strength',
  'SETTING.PASSWORD_POLICY.DIALOG.LABELS.PASSWORD_EXPIRATION_TIME': 'Password expiration time',
  'SETTING.PASSWORD_POLICY.DIALOG.LABELS.PASSWORD_SUFFIX': 'days',
  'SETTING.PASSWORD_POLICY.DIALOG.LABELS.MESSAGE': 'Empty value means that password does not expire',

  'SETTING.PASSWORD_POLICY.MESSAGES.UPDATED_SUCCESSFULLY': 'Password policy has been updated successfully',

  'SETTING.MULTI_FACTOR_POLICY.SECTION_TITLE': 'Two-factor policy',
  'SETTING.MULTI_FACTOR_POLICY.POLICY_REQUIRED': 'Is two-factor authentication required?',
  'SETTING.MULTI_FACTOR_POLICY.TEXT_MESSAGE_ALLOWED': 'Is text message two-factor authentication allowed?',
  'SETTING.MULTI_FACTOR_POLICY.STATUS.ENABLED': 'Yes',
  'SETTING.MULTI_FACTOR_POLICY.STATUS.DISABLED': 'No',

  'SETTING.MULTI_FACTOR_POLICY.DIALOG.TITLE': '{{ SETTING.MULTI_FACTOR_POLICY.SECTION_TITLE }}',
  'SETTING.MULTI_FACTOR_POLICY.LABELS.POLICY_REQUIRED': 'Enforce two-factor configuration',
  'SETTING.MULTI_FACTOR_POLICY.LABELS.POLICY_TEXT_MESSAGE_ALLOWED': 'Allow text message {{ MULTI_FACTOR_AUTHENTICATION_LOWERCASE }}',

  'SETTING.MULTI_FACTOR_POLICY.MESSAGES.UPDATED_SUCCESSFULLY': 'Two-factor policy has been updated successfully',

  'SETTING.ACTIONS.EDIT': 'Edit',
  'SETTING.ACTIONS.EDIT_PASSWORD_POLICY': 'Edit password policy',
  'SETTING.ACTIONS.EDIT_2FA': 'Edit two-factor policy',

  'TENANT_CONFIGURATION.PAGE_TITLE': 'Azure ID tenant configuration',
  'TENANT_CONFIGURATION.LIST.TITLE': 'Allowed Azure tenants',
  'TENANT_CONFIGURATION.LIST.LABELS.TENANT_ID': 'Tenant ID',
  'TENANT_CONFIGURATION.LIST.LABELS.ENABLED_ON': 'Enabled on',
  'TENANT_CONFIGURATION.LIST.LABELS.DOMAINS': 'Domains',
  'TENANT_CONFIGURATION.LIST.LABELS.ACTIONS': 'Actions',

  'TENANT_CONFIGURATION.ADD_NEW_TENANT.TITLE': 'Add tenant',
  'TENANT_CONFIGURATION.ADD_NEW_TENANT.LABELS.AZURETENANTID': 'Tenant ID',
  'TENANT_CONFIGURATION.ADD_NEW_TENANT.LABELS.DOMAINS': 'Domains',

  'TENANT_CONFIGURATION.REMOVE_TENANT.TITLE': 'Are you sure you want to delete tenant {{ displayName }}?',

  'TENANT_CONFIGURATION.MESSAGES.TENANT_CREATED': 'Tenant has been added successfully',
  'TENANT_CONFIGURATION.MESSAGES.TENANT_REMOVED': 'Tenant has been deleted successfully',

  'TENANT_CONFIGURATION.ACTIONS.ADD': 'Add tenant',
  'TENANT_CONFIGURATION.ACTIONS.REMOVE': 'Remove',
  'TENANT_CONFIGURATION.ACTIONS.CANCEL': 'Cancel',
  'TENANT_CONFIGURATION.ACTIONS.SUBMIT': 'Submit',

  'IDENTITY_PROVIDERS.ENTITY_ID': 'Entity Id',

  'IDENTITY_PROVIDERS.SECTION_TITLE': 'Providers',
  'IDENTITY_PROVIDERS.DIALOG.DETAIL_TITLE': 'Identity Provider Configuration',
  'IDENTITY_PROVIDERS.DIALOG.REMOVE_TITLE': 'Are you sure you want to delete {{ displayName }} identity provider?',

  'IDENTITY_PROVIDERS.DETAILS.LABELS.NAME': 'Name',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.SINGLE_SIGN_ON_ENDPOINT_BINDING_TYPE': 'Single sign-on binding type',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.SINGLE_SIGN_ON_ENDPOINT_URL': 'Single sign-on endpoint',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.SINGLE_LOGOUT_ENDPOINT_BINDING_TYPE': 'Single logout binding type',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.SINGLE_LOGOUT_ENDPOINT_URL': 'Single logout endpoint',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.ARTIFACT_RESOLUTION_ENDPOINT_URL': 'Artifact endpoint url',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.IDENTITY_PROVIDER_ENTITY_ID': 'Identity provider entity ID',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.DOMAINS': 'Domains',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.BASE64_IDENTITY_PROVIDER_CERTIFICATES': 'Signing certificates',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.IS_ENABLED': 'Is enabled',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.REQUIRE_ENCRYPTION': 'Require encryption',
  'IDENTITY_PROVIDERS.DETAILS.LABELS.CERTIFICATES': 'Signing certificates',

  'IDENTITY_PROVIDERS.LIST.LABELS.NAME': 'Name',
  'IDENTITY_PROVIDERS.LIST.LABELS.REPLY_URL': 'Reply url',
  'IDENTITY_PROVIDERS.LIST.LABELS.ENABLED': 'Status',
  'IDENTITY_PROVIDERS.LIST.LABELS.ACTIONS': 'Actions',
  'IDENTITY_PROVIDERS.LIST.LABELS.STATUS.ENABLED': 'Enabled',
  'IDENTITY_PROVIDERS.LIST.LABELS.STATUS.DISABLED': 'Disabled',

  'IDENTITY_PROVIDERS.BINDING_TYPE.HTTP_ARTIFACT': 'Http Artifact',
  'IDENTITY_PROVIDERS.BINDING_TYPE.HTTP_POST': 'Http Post',
  'IDENTITY_PROVIDERS.BINDING_TYPE.HTTP_REDIRECT': 'Http Redirect',
  'IDENTITY_PROVIDERS.BINDING_TYPE.SOAP': 'Soap',

  'IDENTITY_PROVIDERS.ACTIONS.DOWNLOAD_ENCRYPTION_CERTIFICATE': 'Download encryption certificates',
  'IDENTITY_PROVIDERS.ACTIONS.DOWNLOAD_SIGNING_CERTIFICATE': 'Download signing certificates',
  'IDENTITY_PROVIDERS.ACTIONS.ADD_PROVIDER': 'Add identity provider',
  'IDENTITY_PROVIDERS.ACTIONS.ADD': 'Add',
  'IDENTITY_PROVIDERS.ACTIONS.DELETE': 'Remove {{ displayName }]',
  'IDENTITY_PROVIDERS.ACTIONS.CONTEXT': 'Take an action on {{ provider }}',

  'IDENTITY_PROVIDERS.MESSAGES.ADDED_SUCCESSFULLY': 'Identity provider has been added successfully',
  'IDENTITY_PROVIDERS.MESSAGES.UPDATED_SUCCESSFULLY': 'Identity provider has been updated successfully',
  'IDENTITY_PROVIDERS.MESSAGES.REMOVED_SUCCESSFULLY': 'Identity provider has been deleted successfully',

  'BRANDING.SYSTEM_NAMES': 'System names',
  'BRANDING.SYSTEM_NAMES.TITLE': 'System names guidance',
  'BRANDING.SYSTEM_NAMES.DESCRIPTION.FULL_NAME':
    'The full name will be used in the navigation and top bar of the product. Short names will be used in the collapsed version of the navigation',
  'BRANDING.SYSTEM_NAMES.DESCRIPTION.FULL_NAME_LENGTH': 'Max characters for the full system name: 40',
  'BRANDING.SYSTEM_NAMES.DESCRIPTION.NAVIGATION':
    '  Choose a short name that will fit within the boundaries of the collapsed navigation. The shorter the name, the better. Update the name and check how it fits in the navigation\n',
  'BRANDING.SYSTEM_NAMES.HELPERS.SYSTEM_NAME_SHORT_NAME': ' 8 characters for short name',

  'BRANDING.SYSTEM_NAMES.LABELS.MYPAY.FULL_NAME': ' MyPay full name',
  'BRANDING.SYSTEM_NAMES.LABELS.MYPAY.SHORT_NAME': ' MyPay short name',

  'BRANDING.SYSTEM_NAMES.LABELS.LMS.FULL_NAME': 'Learning Management System full name',
  'BRANDING.SYSTEM_NAMES.LABELS.LMS.SHORT_NAME': 'Learning Management System short name',

  'BRANDING.SYSTEM_NAMES.LABELS.HR.FULL_NAME': 'Human resources full name',
  'BRANDING.SYSTEM_NAMES.LABELS.HR.SHORT_NAME': ' Human resources short name',

  'BRANDING.SYSTEM_NAMES.LABELS.ACADEMY.FULL_NAME': ' Academy full name',
  'BRANDING.SYSTEM_NAMES.LABELS.ACADEMY.SHORT_NAME': ' Academy short name',

  'BRANDING.SYSTEM_NAMES.LABELS.RECRUITMENT.FULL_NAME': ' Recruitment full name',
  'BRANDING.SYSTEM_NAMES.LABELS.RECRUITMENT.SHORT_NAME': ' Recruitment short name',

  'BRANDING.SYSTEM_NAMES.LABELS.REPORTING.FULL_NAME': ' Reporting full name',
  'BRANDING.SYSTEM_NAMES.LABELS.REPORTING.SHORT_NAME': ' Reporting short name',

  'BRANDING.SYSTEM_NAMES.LABELS.SETTINGS.FULL_NAME': ' Settings full name',
  'BRANDING.SYSTEM_NAMES.LABELS.SETTINGS.SHORT_NAME': ' Settings short name',

  'BRANDING.SYSTEM_NAMES.LABELS.TALENT.FULL_NAME': ' Talent full name',
  'BRANDING.SYSTEM_NAMES.LABELS.TALENT.SHORT_NAME': ' Talent short name',

  'BRANDING.SYSTEM_NAMES.LABELS.TASKS.FULL_NAME': ' Tasks full name',
  'BRANDING.SYSTEM_NAMES.LABELS.TASKS.SHORT_NAME': ' Tasks short name',

  'BRANDING.SYSTEM_NAMES.LABELS.EXTERNAL.FULL_NAME': ' External full name',
  'BRANDING.SYSTEM_NAMES.LABELS.EXTERNAL.SHORT_NAME': ' External short name',

  'BRANDING.LOGO': 'Logo',
  'BRANDING.LOGO_SELECTED': 'Current logo',
  'BRANDING.LOGO_REQUIREMENTS_TITLE': 'Requirements for logo assets',
  'BRANDING.LOGO_REQUIREMENTS_DESCRIPTION':
    'Please provide logo with a min height of 100px and min width of 100px. Max file size is 200kb. Allowed file formats: .jpg .png .jpeg .svg .webp',
  'BRANDING.FAVICON': 'Favicon',
  'BRANDING.FAVICON_SELECTED': 'Current favicon',
  'BRANDING.FAVICON_TITLE': 'Requirements for logo assets',
  'BRANDING.FAVICON_DESCRIPTION':
    'Please provide a favicon with size 16px width and 16px height. Max file size is 20kb. Allowed file formats: .jpg .png .jpeg .svg .webp .ico',
  'BRANDING.BRAND_BACKGROUND': 'Ciphr Sign In Brand background',
  'BRANDING.BRAND_BACKGROUND_TITLE': 'Requirements for background assets',
  'BRANDING.BRAND_BACKGROUND.DESCRIPTION.RESOLUTION': 'Background graphic file needs to be at least 960px wide and 1080px high',
  'BRANDING.BRAND_BACKGROUND.DESCRIPTION.CENTER': 'The graphic will be always automatically centered on the left side of the screen',
  'BRANDING.BRAND_BACKGROUND.DESCRIPTION.SIZE': 'The files size can not exceed 500kb',
  'BRANDING.BRAND_BACKGROUND.DESCRIPTION.FORMAT': 'Allowed file formats: .jpg .png .jpeg .svg .webp',
  'BRANDING.BRAND_BACKGROUND.ARIA.LOGIN_PHOTO': 'A screen with login form applied to main login page',
  'BRANDING.BRAND_BACKGROUND.ARIA.BACKGROUND': 'A screen with branding photo uploaded by client',
  'BRANDING.BRAND_BACKGROUND.SELECTED_BACKGROUND': 'Preview of current background',
  'BRANDING.INTERFACE_PREVIEW': 'Interface elements preview',
  'BRANDING.PREVIEW.FORM.NEW_TASK': 'New task',
  'BRANDING.PREVIEW.FORM.TASK_NAME': 'Task name',
  'BRANDING.PREVIEW.FORM.PRIORITY': 'Priority',
  'BRANDING.PREVIEW.FORM.CANCEL': 'Cancel',
  'BRANDING.PREVIEW.FORM.ADD_TASK': 'Add task',
  'BRANDING.PREVIEW.LIST.RECRUITMENT': 'Recruitment',
  'BRANDING.PREVIEW.LIST.DASHBOARD': 'Dashboard',
  'BRANDING.PREVIEW.LIST.CALENDAR': 'Calendar',
  'BRANDING.PREVIEW.LIST.PIPELINE': 'Pipeline',
  'BRANDING.PREVIEW.LIST.TASKS': 'Tasks',
  'BRANDING.PREVIEW.LIST.MESSAGES': 'Messages',
  'BRANDING.PREVIEW.SWITCHERS.LIST': 'List',
  'BRANDING.PREVIEW.SWITCHERS.CARDS': 'Cards',
  'BRANDING.PREVIEW.SWITCHERS.CHECKBOX': 'Checkbox label',
  'BRANDING.PREVIEW.SWITCHERS.RADIO': 'Radio label',
  'BRANDING.PREVIEW.SWITCHERS.TOGGLE': 'Toggle label',
  'BRANDING.PREVIEW.BUTTON': 'Button',
  'BRANDING.ACTIONS.DISCARD': 'Discard changes',
  'BRANDING.ACTIONS.SAVE': 'Save',
  'BRANDING.ACTIONS.RELOAD': 'Reload',
  'BRANDING.ACTIONS.SKIP': 'Skip',
  'BRANDING.MESSAGES.SUCCESS.TITLE': 'Branding has been saved successfully',
  'BRANDING.MESSAGES.SUCCESS.DESCRIPTION': "To see the changes in the template, click the 'reload' button.",

  'AVATAR.UPLOAD_PHOTO': 'Upload photo',
  'AVATAR.DELETE_PHOTO': 'Delete photo',
  'AVATAR.EDIT_PHOTO': 'Edit photo',
  'AVATAR.HANDLE_AVATAR_PHOTO': 'Manage avatar photo',

  'LOGOUT.DESCRIPTION': 'We have safely logged you out. See you later!',
  'LOGOUT.ICON.DESCRIPTION': 'An icon which present exit door',
  'LOGOUT.ACTIONS.BACK': 'Back to login',

  'RECAPTCHA_MESSAGE': 'Something went wrong with reCaptcha',

  'SECURITY_AUTHORIZATION.DIALOG.TITLE': 'Confirm your identity',
  'SECURITY_AUTHORIZATION.DIALOG.DESCRIPTION': 'Enter code from your default multi factor authentication method',

  'SHARED.ERROR_ICON': 'An error icon which present cones',
  'SHARED.ERROR_MESSAGE': 'An error has occurred.',
  'SHARED.NO_VALUE': 'No data available',
  'SHARED.NO_END_DATE': 'No end date',

  'FILTERS.ROLES.LABEL': 'Roles',
  'FILTERS.ROLES.LABELS.BACKOFFICEADMIN': '{{ USERS.ROLES.BACKOFFICEADMIN }}',
  'FILTERS.ROLES.LABELS.GLOBALADMIN': '{{ USERS.ROLES.GLOBALADMIN }}',
  'FILTERS.ROLES.LABELS.GROUPADMIN': '{{ USERS.ROLES.GROUPADMIN }}',
  'FILTERS.ROLES.LABELS.EMPLOYEE': '{{ USERS.ROLES.EMPLOYEE }}',

  'FILTERS.BLOCKSTATUSES.LABEL': '{{ USERS.LABELS.STATUS }}',
  'FILTERS.BLOCKSTATUSES.LABELS.ACTIVE': '{{ USERS.STATUS.ACTIVE }}',
  'FILTERS.BLOCKSTATUSES.LABELS.NOTACTIVE': '{{ USERS.STATUS.BLOCKED }}',

  'FILE_UPLOAD.BROWSE_FILES': 'Browse files',
  'FILE_UPLOAD.DROP_FILES': 'Drops file here to upload',
  'FILE_UPLOAD.DOWNLOAD_FILE': 'Download certificate',
  'FILE_UPLOAD.REMOVE_FILE': 'Remove file',

  'ERRORS.ACCOUNT_LOCKED_OUT': 'Your account has been blocked because of too many failed login attempts',
  'ERRORS.ACCOUNT_LOCKED_OUT_BY_ADMIN': 'Your account has been blocked, please contact your system administrator',
  'ERRORS.BRANDING_UPDATE_FAILED': 'Update branding operation failed. Please try again. If problem persists contact with administrator',
  'ERRORS.BRANDING_INVALID_IMAGE_FILE_TYPE': 'Image type is not matching allowed file types. Please check requirements for more details.',
  'ERRORS.BRANDING_INVALID_IMAGE_SIZE': 'Image type exceeded allowed file size. Please check requirements for more details.',
  'ERRORS.BRANDING_INVALID_COLOR_HASH': 'Value should match Hex color code eg. #096477',
  'ERRORS.CERTIFICATE_IS_NOT_IN_VALID_FORMAT': 'Certificate is not in valid format',
  'ERRORS.DATE_MUST_BE_IN_THE_FUTURE': '{{ targetProperty }} date must be in future',
  'ERRORS.IDENTITY_ERROR': 'One or more validation errors occurred while updating user information',
  'ERRORS.INVALID_ARGUMENT': 'Provided {{ targetProperty }} is not valid',
  'ERRORS.INVALID_EMAIL_ADDRESS': 'Provided email is invalid',
  'ERRORS.INVALID_CREDENTIALS': 'Invalid email or password',
  'ERRORS.INVALID_PASSWORD': 'Provided password is invalid',
  'ERRORS.INVALID_FORMAT': 'Provided format is invalid',
  'ERRORS.ENTITY_ALREADY_EXISTS': 'Entity with given properties already exists',
  'ERRORS.EXTERNAL_USER_NOT_FOUND': 'User was not found',
  'ERRORS.FORBIDDEN': 'This action can’t be performed',
  'ERRORS.GREATER_THAN': '{{ targetProperty }} must be greater than {{ value }}',
  'ERRORS.GREATER_THAN_OR_EQUAL': '{{ targetProperty }} must be greater than or equal to {{ value }}',
  'ERRORS.PASSWORD_MINIMUM_LENGTH': 'Password does not match minimum length requirement',
  'ERRORS.PASSWORD_DIGIT': 'Password does not match digit requirement',
  'ERRORS.PASSWORD_NON_ALPHANUMERIC': 'Password does not match non alphanumeric requirement',
  'ERRORS.PASSWORDS_ARE_NOT_EQUAL': 'New and confirmed passwords should be equal',
  'ERRORS.PASSWORD_UPPERCASE': 'Password does not match uppercase requirement',
  'ERRORS.PASSWORD_LOWERCASE': 'Password does not match lowercase requirement',
  'ERRORS.PASSWORD_UNQIUE_CHARACTERS': 'Password does not match unique characters requirement',
  'ERRORS.PASSWORD_FORBIDDEN': 'Password is in the black list',
  'ERRORS.PASSWORD_ALREADY_USED': 'Provided password was already used',
  'ERRORS.UNIQUE': 'Property {{ targetProperty }} must be unique. Invalid value: {{ value }} ',
  'ERRORS.UNIQUE_COLLECTION': '{{ targetProperty }} with {{ value }} already exists',
  'ERRORS.UNKNOWN': 'Unknown error',
  'ERRORS.USER_RESET_PASSWORD_FORBIDDEN_FOR_NOT_ACTIVE_ACCOUNT': 'Cannot reset password for inactive account',
  'ERRORS.REQUIRED': 'Field {{ targetProperty }} is required',
  'ERRORS.RESET_PASSWORD_FORBIDDEN_FOR_NOT_ACTIVE_ACCOUNT': 'Cannot reset password for inactive account ',
  'ERRORS.RESET_PASSWORD_FORBIDDEN_BY_ADMIN': 'Cannot reset password for account locked by administrator',
  'ERRORS.ROLE_ASSIGNMENT_FORBIDDEN': 'Role assignment is forbidden',
  'ERRORS.INVALID_ABSOLUTE_URL': '{{ targetProperty }} must be valid absolute URL',
  'ERRORS.NOT_FOUND': 'Resource was not found',
  'ERRORS.VALIDATION_ERROR': 'One or more parameters are not valid',
  'ERRORS.TWO_FACTOR_IS_REQUIRED': '{{ MULTI_FACTOR_AUTHENTICATION }} is required to perform this action',
  'ERRORS.TWO_FACTOR_CODE_INVALID': '{{ MULTI_FACTOR_AUTHENTICATION }} code is invalid',
  'ERRORS.TWO_FACTOR_METHOD_DOES_NOT_EXIST': '{{ MULTI_FACTOR_AUTHENTICATION }} method was not verified',
  'ERRORS.TWO_FACTOR_METHOD_ALREADY_DISABLED': '{{ MULTI_FACTOR_AUTHENTICATION }} is already disabled',
  'ERRORS.TWO_FACTOR_ENFORCED': '{{ MULTI_FACTOR_AUTHENTICATION }} is enforced by tenant',
  'ERRORS.UNAUTHORIZED': 'User is not authorized to perform this action',
  'ERRORS.USER_BULK_UPDATE_ADMIN_CANT_EDIT_HIMSELF':
    'The administrator cannot perform bulk operations that may result in his account being blocked.',
  'ERRORS.USER_BULK_UPDATE_ENABLED_FROM_CANT_BE_LESS_THAN_ENABLED_TO': 'Enabled from date must be before enabled to date',
  'ERRORS.VALIDATION_ERROR_USER_BULK_UPDATE_ADMIN_CANT_EDIT_HIMSELF':
    'Operation failed - your account could be locked in result of requested operation',
  'ERRORS.VALIDATION_ERROR_USER_BULK_INVALID_ENABLED_DATES_RANGE_CUMULATIVE':
    'Operation failed - for one or more users Enable From date would be set after Enable To date',
  'ERRORS.VALIDATION_ERROR_USER_BULK_NOT_FOUND_CUMULATIVE':
    'Operation failed - This could be caused by other admin actions. Please reload users list before continuing',
  'ERRORS.VALIDATION_ERROR_USER_BULK_FORBIDDEN_CUMULATIVE':
    'Operation failed - This could be caused by other admin actions. Please reload users list before continuing',
  'ERRORS.VALIDATION_ERROR_USER_BULK_INACTIVE_ACCOUNT_CUMULATIVE': 'Operation failed - one or more users are inactive',
  'ERRORS.UNHANDLED_ERROR': 'An error has occurred',

  'VALIDATIONS.REQUIRED.CODE': 'Code is required',
  'VALIDATIONS.REQUIRED.USER_NAME': 'Email address is required',

  'VALIDATIONS.REQUIRED.PASSWORD': 'Password field is required',
  'VALIDATIONS.REQUIRED.PASSWORD_CONFIRMED': 'Password confirmation field is required',
  'VALIDATIONS.REQUIRED.CURRENT_PASSWORD': 'Current password field is required',
  'VALIDATIONS.REQUIRED.NEW_PASSWORD': 'New password field is required',
  'VALIDATIONS.REQUIRED.NEW_PASSWORD_CONFIRMED': 'Password confirmation field is required',

  'VALIDATIONS.REQUIRED.CREDENTIAL': 'Field is required',
  'VALIDATIONS.REQUIRED.AZURE_TENANT_ID': 'Tenant Id is required',
  'VALIDATIONS.REQUIRED.NAME': 'Name is required',
  'VALIDATIONS.REQUIRED.LOCKOUT_ENDS_ON': 'Locked until date is required',

  'VALIDATIONS.REQUIRED.SINGLE_SIGN_ON_ENDPOINT_BINDING_TYPE': 'Single sign on binding type is required',
  'VALIDATIONS.REQUIRED.SINGLE_SIGN_ON_ENDPOINT_URL': 'Single sign on endpoint url is required',
  'VALIDATIONS.REQUIRED.SINGLE_LOGOUT_ENDPOINT_URL': 'Single logout endpoint url is required',
  'VALIDATIONS.REQUIRED.IDENTITY_PROVIDER_ENTITY_ID': 'Identity provider entity id is required',
  'VALIDATIONS.REQUIRED.BASE64_IDENTITY_PROVIDER_CERTIFICATES': 'At least one certificate is required',
  'VALIDATIONS.REQUIRED.ARTIFACT_RESOLUTION_ENDPOINT_URL': 'Artifact resolution endpoint url is required',
  'VALIDATIONS.REQUIRED.FULL_NAME': 'Full name field is required',
  'VALIDATIONS.REQUIRED.SHORT_NAME': 'Short name field is required',
  'VALIDATIONS.REQUIRED.2FA_METHOD': '{{ MULTI_FACTOR_AUTHENTICATION }} method is required',

  'VALIDATIONS.REQUIRED': 'Field is required',
  'VALIDATIONS.CERTIFICATE_EXTENSION': 'Invalid file format. Allowed formats: {{ fileExtensions }}',
  'VALIDATIONS.EMAIL': 'Invalid email format',
  'VALIDATIONS.PASSWORD': 'Password must be valid with password policy',
  'VALIDATIONS.MOBILE_PHONE_PATTERN': 'Please provide phone number in valid format with country prefix eg. +55123123123',
  'VALIDATIONS.MISMATCH': 'New password fields need to match',
  'VALIDATIONS.PATTERN.AZURE_TENANT_ID': 'Invalid format. Please put value in UUIDv4 format',
  'VALIDATIONS.MAXLENGTH.NAME': 'Max length is equal to: {{ requiredLength }}',
  'VALIDATIONS.MAXLENGTH.FULL_NAME': 'Max length for full name is {{ requiredLength }}',
  'VALIDATIONS.MAXLENGTH.SHORT_NAME': 'Max length for short name is {{ requiredLength }}',
  'VALIDATIONS.MAX_FILE_SIZE': 'Exceed file size',
  'VALIDATIONS.MIN_DATE': 'Provide a valid date',
  'VALIDATIONS.FILESIZE': 'Exceed file size',
  'VALIDATIONS.FILEFORMAT': 'Provide a valid format',
  'VALIDATIONS.HTTP_PROTOCOL.SINGLE_SIGN_ON_ENDPOINT_URL':
    '{{ IDENTITY_PROVIDERS.DETAILS.LABELS.SINGLE_SIGN_ON_ENDPOINT_URL }} have to match to http or https protocol',
  'VALIDATIONS.HTTP_PROTOCOL.ARTIFACT_RESOLUTION_ENDPOINT_URL':
    '{{ IDENTITY_PROVIDERS.DETAILS.LABELS.ARTIFACT_RESOLUTION_ENDPOINT_URL }} have to match to http or https protocol',
  'VALIDATIONS.HTTP_PROTOCOL.SINGLE_LOGOUT_ENDPOINT_URL':
    '{{ IDENTITY_PROVIDERS.DETAILS.LABELS.SINGLE_LOGOUT_ENDPOINT_URL }} have to match to http or https protocol',
  'VALIDATIONS.OLD_DATE.LOCKOUT_ENDS_ON': 'Date must be in future',

  'VALIDATION.REQUIRED': 'Field is required',
  'VALIDATION.EMAIL': 'Field must contain email format',
  'VALIDATION.UNIQUE_EMAIL': 'Email must be unique',
  'VALIDATION.PATTERN': 'Provide a valid format',
  'VALIDATION.MIN_DATE': 'Provide a valid date',

  'VALIDATION.REQUIRED.DOMAIN': 'At least one domain is required',

  'COMMON.ACTIONS.ADD': 'Add',
  'COMMON.ACTIONS.ACCEPT': 'Accept',
  'COMMON.ACTIONS.AGREE': 'Agree',
  'COMMON.ACTIONS.APPLY': 'Apply',
  'COMMON.ACTIONS.BACK': 'Back',
  'COMMON.ACTIONS.CANCEL': 'Cancel',
  'COMMON.ACTIONS.CONFIRM': 'Confirm',
  'COMMON.ACTIONS.CREATE': 'Create',
  'COMMON.ACTIONS.CLEAR_ALL': 'Clear all',
  'COMMON.ACTIONS.CLOSE': 'Close',
  'COMMON.ACTIONS.DECLINE': 'Decline',
  'COMMON.ACTIONS.DELETE': 'Delete',
  'COMMON.ACTIONS.DOWNLOAD': 'Download',
  'COMMON.ACTIONS.DUPLICATE': 'Duplicate',
  'COMMON.ACTIONS.EDIT': 'Edit',
  'COMMON.ACTIONS.I_UNDERSTAND': 'I understand',
  'COMMON.ACTIONS.NEXT': 'Next',
  'COMMON.ACTIONS.REMOVE': 'Remove',
  'COMMON.ACTIONS.SAVE': 'Save',
  'COMMON.ACTIONS.SORT': 'Sort',
  'COMMON.ACTIONS.SUBMIT': 'Submit',
  'COMMON.ACTIONS.USE': 'Use',
  'COMMON.ACTIONS.VIEW_DETAILS': 'View details',
  'COMMON.ARIA.ACTIONS.BACK_BUTTON': 'Back button',
  'COMMON.ARIA.ACTIONS.BULK_BUTTON': 'Bulk actions',
  'COMMON.ARIA.ACTIONS.TOGGLE_ARROW': 'Toggle expansion panel',
  'COMMON.ARIA.ACTIONS.DELETE_ITEM': 'Delete {{ displayName }}?',
  'COMMON.ARIA.INPUTS.AUTOMATIC_SEARCH': 'Search over menu. During typing the search results will be narrowed down to the entered phrase',
  'COMMON.COLLECTIONS.ARIA.BUTTONS.CLOSE': 'Close filters drawer',
  'COMMON.COLLECTIONS.ARIA.BUTTONS.FILTER_HEADER': 'Toggle button for {{filterKey}} filter',
  'COMMON.COLLECTIONS.ARIA.BUTTON.OVERLAY_MENU': 'Button with three dots to toggle overlay menu',
  'COMMON.COLLECTIONS.ARIA.BUTTONS.TOGGLE_DRAWER': 'Toggle filters drawer',
  'COMMON.COLLECTIONS.ARIA.SELECT.PAGE_SIZE': 'Page size',
  'COMMON.COLLECTIONS.EMPTY.NO_RECORDS.DESCRIPTION': 'It appears that there are no records in this table',
  'COMMON.COLLECTIONS.EMPTY.NO_RECORDS.HEADER': 'No records here',
  'COMMON.COLLECTIONS.EMPTY.NO_RESULTS.DESCRIPTION': 'It seems that we didn’t find what you were looking for',
  'COMMON.COLLECTIONS.EMPTY.NO_RESULTS.HEADER': 'No result found',
  'COMMON.COLLECTIONS.FILTERING.APPLIED_FILTERS_NUMBER_BADGE': '{{filtersNumber}} filters applied',
  'COMMON.COLLECTIONS.FILTERING.DRAWER.ACTIONS.CLEAR_ALL': 'Clear all',
  'COMMON.COLLECTIONS.FILTERING.DRAWER.ACTIONS.FILTER': 'Filter',
  'COMMON.COLLECTIONS.FILTERING.DRAWER.HEADER': 'Filters',
  'COMMON.COLLECTIONS.HEADERS.ACTIONS': 'Actions',
  'COMMON.COLLECTIONS.HEADERS.SELECT': 'Select',
  'COMMON.COLLECTIONS.MULTI_SELECTION.SELECT_ALL_ON_PAGE': 'Select all on page',
  'COMMON.COLLECTIONS.MULTI_SELECTION.SELECTION_SIZE': '{{itemsNumber}} selected',
  'COMMON.COLLECTIONS.PAGING.PAGE_SIZE_LABEL': 'results per page',
  'COMMON.COLLECTIONS.PAGING.RANGE_INFO': '{{pageRange}} of {{totalLength}}',
  'COMMON.COLLECTIONS.PAGING.RESULTS_INFO': '{{rangeInfo}} results',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.ACTIONS.DISCARD_CHANGES': 'Discard changes',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.ACTIONS.RETURN_TO_PAGE': 'Return to page',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.DESCRIPTION': 'You are about to leave page without saving changes. Are you sure you want to do this?',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.TITLE': 'Unsaved changes',
  'COMMON.LABELS.ANONYMOUS': 'Anonymous',
  'COMMON.LABELS.AND': 'And',
  'COMMON.LABELS.CATEGORY': 'Category',
  'COMMON.LABELS.DATE': 'Date',
  'COMMON.LABELS.DEPARTMENT': 'Department',
  'COMMON.LABELS.DESCRIPTION': 'Description',
  'COMMON.LABELS.DETAILS': 'Details',
  'COMMON.LABELS.DISABLED': 'Disabled',
  'COMMON.LABELS.DISMISS': 'Dismiss',
  'COMMON.LABELS.EMPTY': 'Empty',
  'COMMON.LABELS.ENABLED': 'Enabled',
  'COMMON.LABELS.FALSE': 'False',
  'COMMON.LABELS.NAME': 'Name',
  'COMMON.LABELS.NOTES': 'Notes',
  'COMMON.LABELS.NO': 'No',
  'COMMON.LABELS.STATUS': 'Status',
  'COMMON.LABELS.TRUE': 'True',
  'COMMON.LABELS.TYPE': 'Type',
  'COMMON.LABELS.YES': 'Yes',
  'COMMON.NOT_APPLICABLE': 'Not applicable',
  'COMMON.PAGE_NOT_ALLOWED.DESCRIPTION_1': 'Sorry, you currently do not have access to view this content.',
  'COMMON.PAGE_NOT_ALLOWED.DESCRIPTION_2': 'Please check your permissions or contact your system administrator for assistance.',
  'COMMON.PAGE_NOT_ALLOWED.HEADER': 'Access restricted',
  'COMMON.PAGE_NOT_FOUND.DESCRIPTION': "Oops! The page you're looking for seems to have disappeared.",
  'COMMON.PAGE_NOT_FOUND.HEADER': 'Page not found',
  'COMMON.PLACEHOLDERS.ALL': 'All',
  'COMMON.PLACEHOLDERS.LOADING': 'Loading...',
  'COMMON.PLACEHOLDERS.SEARCH': 'Search...',
  'COMMON.PLACEHOLDERS.SELECT': 'Select',
  'COMMON.PRODUCTS.HR': 'Human resources',
  'COMMON.COOKIE_BANNER.TITLE': 'Cookie policy',
  'COMMON.COOKIE_BANNER.DESCRIPTION':
    'This web site requires the use of cookies in order to function. We do not use cookies for any other purpose than to maintain necessary information about your current interaction with the site. For more information about the cookies that this web site uses please see our',
  'COMMON.COOKIE_BANNER.LINK': 'privacy notice',
  'COMMON.COOKIE_BANNER.ACTIONS.CONSENT': 'Acknowledge',
  'COMMON.SELECT.DEFAULT_VALUE': 'Unassigned',
  'COMMON.SENSITIVE_DATA.TITLE': 'Sensitive data',
  'COMMON.SENSITIVE_DATA.ARIA_TOOGLE': 'Button with an eye icon where data is available to read and eye-crossed when the data is hidden',
  'COMMON.SENSITIVE_DATA.DESCRIPTION':
    'We hide your sensitive data to protect your privacy. For watching the data please turn off the Sensitive toggle button in menu.',
  'COMMON.SNACK_BAR.HTTP_ERROR.MESSAGE': 'Something went wrong.',

  'COMMON.UPLOAD.LABELS.FILE_TO_UPLOAD': 'File to upload',
  'COMMON.UPLOAD.LABELS.SELECT_FILE': 'Select file',
};
