import { Store } from '@ngrx/store';
import { accountFeatureActions } from '../../state/account/actions/account-feature.actions';
import { selectUserName } from '../../state/account/account.selectors';
import { filter, first } from 'rxjs';

export const accountConfigFactory = (store: Store) => () => {
  store.dispatch(accountFeatureActions.loadAccountInformation());

  return store.select(selectUserName).pipe(
    filter((userName) => userName !== null),
    first(),
  );
};
