import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const timeBetweenValidator = (startDateTime: string, endDateTime: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const controlDate = new Date(control.value);
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);

    if (controlDate < startDate || controlDate > endDate) {
      const minDate = startDate.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' });
      const maxDate = endDate.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' });

      return { timeBetween: { minDate, maxDate } };
    }

    return null;
  };
};
