import { createFeature, createReducer, on } from '@ngrx/store';
import { newPasswordApiActions } from './actions/new-password-api.actions';
import { CoreState } from '../../login/state/login.reducer';
import { newPasswordFeaturesActions } from './actions/new-password-features.actions';
import { PasswordPolicyStrength } from '../../models/account/password-policy.model';

export type NewPasswordState = CoreState & {
  userId: string | null;
  token: string | null;
  passwordPolicy: PasswordPolicyStrength | null;
};

export const initialState: NewPasswordState = {
  errorMessage: null,
  loadingActionState: false,
  userId: null,
  token: null,
  passwordPolicy: null,
};

export const newPasswordFeature = createFeature({
  name: 'newPasswordFeature',
  reducer: createReducer(
    initialState,
    on(newPasswordFeaturesActions.setNewPassword, (state) => ({ ...state, loadingActionState: true })),
    on(newPasswordApiActions.passwordPolicyLoadedSuccessfully, (state, { policy }) => ({ ...state, passwordPolicy: policy })),
    on(newPasswordApiActions.passwordPolicyLoadedFailed, (state, { error }) => ({ ...state, errorMessage: error })),
    on(newPasswordApiActions.passwordChangeSuccessfully, (state) => ({
      ...state,
      loadingActionState: false,
      userId: null,
      token: null,
    })),
    on(newPasswordApiActions.passwordChangeFailed, (state, { error }) => ({
      ...state,
      loadingActionState: false,
      errorMessage: error,
    })),
    on(newPasswordFeaturesActions.setToken, (state, { token }) => ({ ...state, token })),
    on(newPasswordFeaturesActions.setUserId, (state, { userId }) => ({ ...state, userId })),
    on(newPasswordFeaturesActions.reCaptchaFailed, (state) => ({ ...state, loadingActionState: false })),
  ),
});
