import { inject, Injectable } from '@angular/core';
import { AccountService } from '../../account.service';
import { catchError, EMPTY, map, mergeMap, of, switchMap, throwError } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { resetPasswordApiActions } from './actions/reset-password-api.actions';
import { resetPasswordFeaturesActions } from './actions/reset-password-features.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { SSOHttpErrorResponse } from '../../models/errors.model';
import { RecaptchaService } from '../../core/recaptcha/recaptcha.service';

@Injectable()
export class ResetPasswordEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private accountService = inject(AccountService);
  private recaptchaService = inject(RecaptchaService);

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPasswordFeaturesActions.resetPassword),
      mergeMap(({ userName }) => {
        return this.recaptchaService.execute('submit').pipe(
          switchMap((recaptchaToken) => {
            return this.accountService.resetPassword({ userName, recaptchaToken }).pipe(
              map(() => resetPasswordApiActions.resetPasswordSuccessfully()),
              catchError((httpErrorResponse: SSOHttpErrorResponse) => {
                this.store.dispatch(resetPasswordApiActions.resetPasswordFailed({ error: httpErrorResponse.error || null }));
                return httpErrorResponse.error ? EMPTY : throwError(() => httpErrorResponse);
              }),
            );
          }),
          catchError((error) =>
            error instanceof HttpErrorResponse ? throwError(() => error) : of(resetPasswordFeaturesActions.reCaptchaFailed()),
          ),
        );
      }),
    ),
  );
}
