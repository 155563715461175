import { Store } from '@ngrx/store';
import { accountState } from '../../state/account/account.selectors';
import { filter, switchMap } from 'rxjs';
import { UserSessionService } from '@ciphr/core/user-session';

export const ssoSessionFactory = (store: Store, userSessionService: UserSessionService) => () => {
  store
    .select(accountState)
    .pipe(
      filter((accountState) => !!accountState.userName),
      switchMap(() => userSessionService.fetchUserSessionScript()),
    )
    .subscribe();
};
