import { inject, Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs';

import { PENDO } from './pendo.factory';
import { PendoOptions } from './pendo-options.type';

@Injectable({ providedIn: 'root' })
export class PendoService {
  private readonly pendo = inject(PENDO);
  private readonly router = inject(Router);

  initialize(options: PendoOptions): void {
    if (!isDevMode()) {
      this.pendo.initialize(options);
      this.trackPageLoads();
    }
  }

  private trackPageLoads(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.pendo.pageLoad());
  }
}
