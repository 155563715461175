import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { uiActions } from './ui.actions';
import { tap } from 'rxjs';
import { SnackBarService } from '@ciphr/ui';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class UiEffects {
  private actions$ = inject(Actions);

  private snackbarService = inject(SnackBarService);
  private translocoService = inject(TranslocoService);

  displaySuccessSnackbar = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uiActions.displaySuccessToast),
        tap(({ toastMessage, params, lang }) =>
          this.snackbarService.open(this.translocoService.translate(toastMessage, params, lang), 'success', 'secondary'),
        ),
      ),
    { dispatch: false },
  );

  displayErrorSnackbar = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uiActions.displayErrorToast),
        tap(({ toastMessage, params, lang }) =>
          this.snackbarService.open(this.translocoService.translate(toastMessage, params, lang), 'error', 'secondary'),
        ),
      ),
    { dispatch: false },
  );
}
