import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { LoginStep } from '../login.reducer';
import { SignInRequestModel } from '../../../models/account/sign-in.models';
import { SsoSingleSignOnRequestModel } from '../../../models/account/sso-sign-in.models';
import { TfaCodeProvider } from '../../../models/profile/tfa-code-provider.enum';
import { TfaSignInProvider } from '../../../models/account/tfa-sign-in-provider.enum';
import { VerifyTwoFactorCodeRequestModel } from '../../../models/account/verify-tfa-code-request.model';

export const loginFeatureActions = createActionGroup({
  source: 'Login Feature',
  events: {
    'Load Single Sign On Information': emptyProps(),
    'Set Login Step': props<{ step: LoginStep }>(),
    'Clear messages': emptyProps(),
    'Login': props<{ signInRequestModel: Omit<SignInRequestModel, 'recaptchaToken'> }>(),
    'Login Via Single Sign On': props<Omit<SsoSingleSignOnRequestModel, 'recaptchaToken'>>(),
    'Login Via Two Factor': props<{ verifyTwoFactorCodeRequestModel: Omit<VerifyTwoFactorCodeRequestModel, 'isPersistent' | 'method'> }>(),
    'Change Two Factor Authentication Method': props<{ method: TfaSignInProvider }>(),
    'Send Code Again': props<{ method: TfaCodeProvider }>(),
    'Send Code After Changed Two Factor Method': props<{ method: TfaCodeProvider }>(),
    'ReCaptcha Failed': emptyProps(),
    'Redirect To Desire Application': emptyProps(),
    'Clear State': emptyProps(),
    'Set Remember Me': props<{ rememberMe: boolean }>(),
  },
});
