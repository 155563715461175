import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsAdmin } from '../state/account/account.selectors';

export const adminGuard: CanMatchFn = (): Observable<boolean | UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectIsAdmin).pipe(map((isAdmin) => isAdmin || router.createUrlTree(['login'])));
};
