import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  private router = inject(Router);

  intercept<RequestBody>(req: HttpRequest<RequestBody>, next: HttpHandler): Observable<HttpEvent<RequestBody>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Gone) {
          this.router.navigate(['/not-found']);
        }
        return throwError(() => error);
      }),
    );
  }
}
