import { inject, Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { DEFAULT_ERRORS_TRANSLATIONS_PATH, ErrorMessageTranslationPathProviderToken } from './validation.types';
import { TranslocoService } from '@ngneat/transloco';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { UpperSnakeCasePipe } from '@ciphr/ui';

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorMessageService {
  private errorMessageTranslationPathProviderToken = inject(ErrorMessageTranslationPathProviderToken, { optional: true });
  private defaultErrorMessagesTranslationsPath = inject(DEFAULT_ERRORS_TRANSLATIONS_PATH);

  private translocoService = inject(TranslocoService);
  private liveAnnouncer = inject(LiveAnnouncer);
  private upperSnakeCasePipe = inject(UpperSnakeCasePipe);

  translateValidationError(errors: ValidationErrors, controlName: string | number): string {
    const [errorKey, errorParams] = Object.entries(errors)[0];

    const upperSnakeCasedErrorKey = this.upperSnakeCasePipe.transform(errorKey);
    const upperSnakeCasedControlName = this.upperSnakeCasePipe.transform(controlName.toString());

    const translations = this.translocoService.getTranslation(this.translocoService.getActiveLang());
    const translation =
      translations[`${this.errorMessageTranslationPathProviderToken}.${upperSnakeCasedErrorKey}.${upperSnakeCasedControlName}`];

    return this.errorMessageTranslationPathProviderToken
      ? translation
        ? this.translocoService.translate(
            `${this.errorMessageTranslationPathProviderToken}.${upperSnakeCasedErrorKey}.${upperSnakeCasedControlName}`,
            errorParams,
          )
        : this.translocoService.translate(`${this.errorMessageTranslationPathProviderToken}.${upperSnakeCasedErrorKey}`, errorParams)
      : this.translocoService.translate(`${this.defaultErrorMessagesTranslationsPath}.${upperSnakeCasedErrorKey}`, errorParams);
  }

  announceA11Y(message: string): void {
    this.liveAnnouncer.announce(message);
  }
}
