import { InjectionToken } from '@angular/core';
import { CookieBannerConfig } from './cookie-banner-config.type';

export const COOKIE_BANNER_CONFIG = new InjectionToken<CookieBannerConfig>('Cookie Banner Config', {
  factory: () => ({
    name: 'Cookiemessage',
    value: 'true',
    expiration: 365,
  }),
  providedIn: 'root',
});
