@if (!isCookieConsented()) {
  <chr-inline-message *transloco="let t" palette="neutral" [closable]="false" [title]="t('COMMON.COOKIE_BANNER.TITLE')">
    <p>
      {{ t("COMMON.COOKIE_BANNER.DESCRIPTION") }}
      <a href="https://www.ciphr.com/cookies/" target="_blank">{{ t("COMMON.COOKIE_BANNER.LINK") }}.</a>
    </p>
    <chr-button
      slot="footer"
      size="s"
      [label]="t('COMMON.COOKIE_BANNER.ACTIONS.CONSENT')"
      variant="secondary"
      palette="neutral"
      data-test-id="acknowledgeCookiePolicyButton"
      (chr-action)="consentCookie()"
    />
  </chr-inline-message>
}
