import { inject, Injectable, signal } from '@angular/core';
import { filter, tap } from 'rxjs';

import { CUSTOM_APP_CONFIG } from '../core/factories/custom-config-initializer-factory';

@Injectable({ providedIn: 'root' })
export class RecaptchaService {
  private appConfig = inject(CUSTOM_APP_CONFIG);

  private recaptchaSiteKeyV3 = signal('');
  reCaptchaSiteKey = this.recaptchaSiteKeyV3.asReadonly();

  configureReCaptchaSiteKey$ = this.appConfig.asObservable().pipe(
    filter(Boolean),
    tap(({ reCaptchaSiteKey }) => this.recaptchaSiteKeyV3.set(reCaptchaSiteKey)),
  );
}
