import { inject, Injectable } from '@angular/core';
import { AccountService } from '../../account.service';
import { catchError, EMPTY, map, mergeMap, of, switchMap, tap, throwError, withLatestFrom } from 'rxjs';
import { newPasswordApiActions } from './actions/new-password-api.actions';
import { newPasswordFeaturesActions } from './actions/new-password-features.actions';
import { selectNewPasswordToken, selectNewPasswordUserId } from './new-password.selectors';
import { SSOHttpErrorResponse } from '../../models/errors.model';
import { loginFeatureActions } from '../../login/state/actions/login-feature.actions';
import { uiActions } from '../../state/ui.actions';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { RecaptchaService } from '../../core/recaptcha/recaptcha.service';

@Injectable()
export class NewPasswordEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private accountService = inject(AccountService);
  private router = inject(Router);
  private recaptchaService = inject(RecaptchaService);

  loadPasswordPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newPasswordFeaturesActions.loadPasswordPolicy),
      mergeMap(() =>
        this.accountService
          .fetchPasswordPolicyStrength()
          .pipe(map(({ policyStrength }) => newPasswordApiActions.passwordPolicyLoadedSuccessfully({ policy: policyStrength }))),
      ),
    ),
  );

  setNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newPasswordFeaturesActions.setNewPassword),
      withLatestFrom(this.store.select(selectNewPasswordUserId), this.store.select(selectNewPasswordToken)),
      mergeMap(([{ password, passwordConfirmed }, userId, token]) => {
        return this.recaptchaService.execute('submit').pipe(
          switchMap((recaptchaToken) => {
            return this.accountService.setNewPassword({ password, passwordConfirmed, token, userId, recaptchaToken }).pipe(
              map(() => newPasswordApiActions.passwordChangeSuccessfully()),
              catchError((httpErrorResponse: SSOHttpErrorResponse) => {
                this.store.dispatch(newPasswordApiActions.passwordChangeFailed({ error: httpErrorResponse.error || null }));
                return httpErrorResponse.error ? EMPTY : throwError(() => httpErrorResponse);
              }),
            );
          }),
          catchError((error) => (error instanceof HttpErrorResponse ? throwError(() => error) : of(loginFeatureActions.reCaptchaFailed()))),
        );
      }),
    ),
  );

  passwordChangedSuccessfully = createEffect(() =>
    this.actions$.pipe(
      ofType(newPasswordApiActions.passwordChangeSuccessfully),
      tap(() => this.router.navigate(['/login'])),
      map(() => uiActions.displaySuccessToast({ toastMessage: 'NEW_PASSWORD.MESSAGES.PASSWORD_CHANGED' })),
    ),
  );
}
