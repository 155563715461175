import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CookiesService } from './cookies-banner.service';
import { ChrComponentsModule } from '@ciphr-design-system/angular';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ciphr-cookies-banner',
  standalone: true,
  imports: [ChrComponentsModule, TranslocoDirective],
  templateUrl: './cookies-banner.component.html',
  styleUrl: './cookies-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesBannerComponent {
  private cookiesService = inject(CookiesService);

  isCookieConsented = this.cookiesService.isConsented;

  public consentCookie(): void {
    this.cookiesService.consentCookie();
  }
}
