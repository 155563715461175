import { Inject, Injectable, signal } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { COOKIE_BANNER_CONFIG } from './cookie-banner-config.token';
import { CookieBannerConfig } from './cookie-banner-config.type';

@Injectable({ providedIn: 'root' })
export class CookiesService {
  private _isConsented = signal(false);
  isConsented = this._isConsented.asReadonly();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(COOKIE_BANNER_CONFIG) private cookieBannerConfig: CookieBannerConfig,
  ) {
    const { name, value, expiration } = cookieBannerConfig;
    this.handleCookie(name, value, expiration);
  }

  public consentCookie(): void {
    const { name, value, expiration } = this.cookieBannerConfig;
    this.setCookie(name, value, expiration);

    this._isConsented.set(true);
  }

  private getCookie(name: string): string {
    const ca: Array<string> = decodeURIComponent(this.document.cookie).split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  private setCookie(name: string, value: string, expireDays: number, path = '/'): void {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `; path=${path}` : '';

    this.document.cookie = `${name}=${value}; ${expires}${cpath};`;
  }

  private handleCookie(cookieName: string, cookieValue: string, expireDays: number): void {
    const cookie = this.getCookie(cookieName);
    if (cookie) {
      this.setCookie(cookieName, cookieValue, expireDays);
      this._isConsented.set(true);
    } else {
      this._isConsented.set(false);
    }
  }
}
