import { DestroyRef, Directive, ElementRef, inject, OnInit } from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';

import { EMPTY, fromEvent, merge, switchMap } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ValidationErrorMessageService } from './validation-error-message.service';
import { getInternalStatusChangeOfControl } from '@ciphr/libs/utils/forms';

@Directive({
  standalone: true,
  selector: '[ciphrValidationInlineError]',
})
export class ValidationErrorInlineDirective implements OnInit {
  private elementRef = inject(ElementRef);
  private destroyRef = inject(DestroyRef);

  private formGroup = inject(FormGroupDirective, { host: true, optional: true });
  private ngControl = inject(NgControl);

  private validationErrorMessageService = inject(ValidationErrorMessageService);

  ngOnInit() {
    const { control } = this.ngControl;

    if (control) {
      merge(
        this.formGroup?.ngSubmit.asObservable() ?? EMPTY,
        fromEvent(this.elementRef.nativeElement, 'blur').pipe(switchMap(() => getInternalStatusChangeOfControl(control))),
      )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          const dsInputNativeElement = this.elementRef.nativeElement;
          const errors = this.ngControl?.errors;

          dsInputNativeElement.error = !!errors && (this.ngControl?.dirty || !!this.formGroup?.submitted);

          if (errors && this.ngControl?.name) {
            const controlName = this.ngControl?.name;

            dsInputNativeElement.explicitValidationMessage = this.validationErrorMessageService.translateValidationError(
              errors,
              controlName,
            );
            dsInputNativeElement.error = true;
          } else {
            dsInputNativeElement.explicitValidationMessage = '';
            dsInputNativeElement.error = false;
          }
        });
    }
  }
}
