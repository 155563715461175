import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'upperSnakeCase',
})
export class UpperSnakeCasePipe implements PipeTransform {
  transform(value: string): string {
    return (
      value
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        ?.map((fragment) => fragment.toUpperCase())
        .join('_') || ''
    );
  }
}
