import { createFeature, createReducer, on } from '@ngrx/store';
import { resetPasswordApiActions } from './actions/reset-password-api.actions';
import { CoreState } from '../../login/state/login.reducer';
import { resetPasswordFeaturesActions } from './actions/reset-password-features.actions';

export type ResetPasswordState = CoreState & {
  isPasswordSuccessfullyReset: boolean;
};

export const initialState: ResetPasswordState = {
  errorMessage: null,
  loadingActionState: false,
  isPasswordSuccessfullyReset: false,
};

export const resetPasswordFeature = createFeature({
  name: 'resetPasswordFeature',
  reducer: createReducer(
    initialState,
    on(resetPasswordFeaturesActions.resetPassword, (state) => ({ ...state, loadingActionState: true })),
    on(resetPasswordFeaturesActions.resetState, () => ({ ...initialState })),
    on(resetPasswordApiActions.resetPasswordSuccessfully, (state) => ({
      ...state,
      loadingActionState: false,
      isPasswordSuccessfullyReset: true,
    })),
    on(resetPasswordApiActions.resetPasswordFailed, (state, { error }) => ({
      ...state,
      loadingActionState: false,
      errorMessage: error,
    })),
    on(resetPasswordFeaturesActions.reCaptchaFailed, (state) => ({ ...state, loadingActionState: false })),
  ),
});
