import { inject, Injectable } from '@angular/core';

import { filter, map } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { appNavigationFeatureActions } from '@ciphr/core/app-navigation/state';
import { collectionsFilteringFeatureActions } from '@ciphr/shared/collections-filtering/state';

@Injectable()
export class SideContentsEffects {
  private actions$ = inject(Actions);

  closeFiltersDrawer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appNavigationFeatureActions.setNavigationOpened),
      filter(({ navigationOpened }) => !!navigationOpened),
      map(() => collectionsFilteringFeatureActions.closeDrawer()),
    ),
  );

  closeSideNav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(collectionsFilteringFeatureActions.drawerOpened),
      map(() => appNavigationFeatureActions.setNavigationOpened({ navigationOpened: false })),
    ),
  );
}
