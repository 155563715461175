import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SSOHttpErrorResponse } from '../../../models/errors.model';

export const resetPasswordApiActions = createActionGroup({
  source: 'Login Api',
  events: {
    'Reset Password Successfully': emptyProps(),
    'Reset Password Failed': props<{ error: SSOHttpErrorResponse['error'] }>(),
  },
});
