import { createFeature, createReducer, on } from '@ngrx/store';
import { CoreAccountType, SSORole } from '@ciphr/utils/interfaces';
import { accountApiActions } from './actions/account-api.actions';
import { accountFeatureActions } from './actions/account-feature.actions';

export type AccountType = CoreAccountType & {
  avatar: string;
  defaultAppUrl: string;
  displayName: string;
  isAdmin: boolean;
  language: string;
  role: SSORole;
};

export interface AccountState {
  avatar: string;
  id: number | null;
  userName: string | null;
  defaultAppUrl: string | null;
  displayName: string | null;
  isAdmin: boolean;
  role: SSORole | null;
  language: string | null;
}

export const initialState: AccountState = {
  avatar: '',
  id: null,
  userName: null,
  defaultAppUrl: null,
  displayName: null,
  isAdmin: false,
  role: null,
  language: null,
};

export const accountFeature = createFeature({
  name: 'accountFeature',
  reducer: createReducer(
    initialState,
    on(accountApiActions.loadedAccountInformationSuccessfully, (state, { account }) => ({
      ...state,
      avatar: account.avatar,
      id: Number(account.id),
      role: account.role,
      isAdmin: account.isAdmin,
      language: account.language,
      userName: account.userName,
      defaultAppUrl: account.defaultAppUrl,
      displayName: account.displayName,
    })),
    on(accountApiActions.loadedAccountInformationFailed, (state) => ({
      ...state,
      avatar: '',
      id: null,
      userName: '',
      isAdmin: false,
      role: null,
      language: null,
      defaultAppUrl: null,
    })),
    on(accountFeatureActions.clearState, (state) => ({
      ...state,
      avatar: '',
      id: null,
      userName: '',
      isAdmin: false,
      role: null,
      language: null,
    })),
  ),
});
