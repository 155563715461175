import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

import { PendoOptions } from './pendo-options.type';

interface Pendo {
  initialize: (options: PendoOptions) => void;
  pageLoad: (url?: string) => void;
}

const pendoFactory = (): Pendo => {
  const { defaultView } = inject(DOCUMENT);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pendo = defaultView ? ((defaultView as Record<string, any>)['pendo'] as Pendo) : null;

  if (!pendo) {
    throw 'Pendo has not been added to html head';
  }

  return pendo;
};

export const PENDO = new InjectionToken<Pendo>('Pendo', { factory: pendoFactory, providedIn: 'root' });
