import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loginFeatureActions } from '../login/state/actions/login-feature.actions';
import { resetPasswordFeaturesActions } from '../reset-password/state/actions/reset-password-features.actions';
import { newPasswordFeaturesActions } from '../new-password/state/actions/new-password-features.actions';
import { map } from 'rxjs';
import { uiActions } from './ui.actions';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class RecaptchaEffects {
  private actions$ = inject(Actions);
  private translocoService = inject(TranslocoService);

  reCaptchaFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFeatureActions.reCaptchaFailed, newPasswordFeaturesActions.reCaptchaFailed, resetPasswordFeaturesActions.reCaptchaFailed),
      map(() => uiActions.displayErrorToast({ toastMessage: this.translocoService.translate('RECAPTCHA_MESSAGE') })),
    ),
  );
}
