<ng-container *transloco="let t; read: 'COMMON.PAGE_NOT_FOUND'">
  <ciphr-page-header [title]="t('HEADER')" />

  <section>
    <chr-illustration name="error-cones" palette="accent" [title]="t('sso.shared.errorIcon')" />

    <span data-test-id="pageNotFoundHeader">{{ t("HEADER") }}</span>
    <p data-test-id="pageNotFoundDescription">{{ t("DESCRIPTION") }}</p>
  </section>
</ng-container>

